import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PagePrestamosPersonalesSantander = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Prestamos-Santander' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Préstamos Personales del Banco Santander💸'
          subheader='Accede a financiamiento flexible con el Banco Santander: Descubre hasta $2.000.000 en préstamos personales y haz realidad tus proyectos.'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/efectivo-si">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $300.000 💸 </Button>
        </Link>
        <Link to="/prestamos-nacion">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $150.000 🤑 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          Si estás buscando financiamiento para hacer realidad tus sueños, el Súper Préstamo Personal del Santander  es una excelente opción. Con este préstamo, puedes obtener hasta $2.000.000 en cuotas fijas y en pesos, adaptándolo a tus posibilidades y necesidades. A continuación, te contamos todo lo que necesitas saber para solicitarlo.
<br><br>
Clientes Santander: Beneficios exclusivos
<br><br>
Si ya eres cliente del Santander, podrás disfrutar de beneficios exclusivos al solicitar el Súper Préstamo Personal. Estos beneficios están diseñados para premiar tu fidelidad y brindarte ventajas adicionales durante todo el proceso. Además, tendrás la comodidad de gestionar tu préstamo desde Online Banking o la App Santander, sin necesidad de visitar una sucursal.
<br><br>
El préstamo que te conviene: A medida de tus necesidades
<br><br>
El Súper Préstamo Personal del Santander  te brinda la flexibilidad de armarlo a tu medida. Podrás ingresar el monto que necesitas, elegir la cantidad de cuotas y decidir cuándo deseas comenzar a devolverlo. Esto te permite adaptar el préstamo a tus posibilidades y planificar tu presupuesto de manera eficiente.
<br><br>

          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          Recibí el dinero al instante: Rápido y conveniente
<br><br>
Una de las ventajas de este préstamo es que, una vez aprobada tu solicitud, recibirás el dinero en tu cuenta al instante. A través de una sencilla verificación de seguridad, tendrás acceso a los fondos necesas de forma rápida y conveniente, sin largas esperas ni complicados trámites.
<br><br>
Solicitud 100% online: Sin salir de tu casa
<br><br>
El Santander  te ofrece la comodidad de solicitar el Súper Préstamo Personal desde la comodidad de tu hogar o cualquier lugar donde te encuentres. Podrás realizar la solicitud a través de Online Banking o la App Santander, sin la necesidad de ir a una sucursal. Esto te permite ahorrar tiempo y simplificar el proceso.
<br><br>
Modalidades de Súper Préstamo Personal: Tradicional y UVA
<br><br>
El Súper Préstamo Personal del Santander  ofrece dos modalidades: Tradicional y UVA. En ambas modalidades, la tasa de interés es fija y en pesos.
<br><br>
En la modalidad tradicional, el monto de la cuota se mantiene constante durante todo el plazo del préstamo, brindándote certeza sobre el monto a pagar en cada cuota.
<br><br>
En la modalidad UVA, el monto solicitado se establece en Unidades de Valor Adquisitivo (UVAs), cuyo valor se actualiza diariamente según la información proporcionada por el BCRA. El monto de las cuotas y el saldo total de la deuda se ajustarán mensualmente en función del valor de las UVAs al momento del pago.
<br><br>
Monto máximo y cantidad de cuotas: A tu alcance

El monto máximo que puedes solicitar dependerá de tus ingresos y calificación crediticia. Para conocer tu línea de crédito disponible, puedes ingresar a la sección de Préstamos desde Online Banking o la App Santander.
<br><br>
En cuanto a la cantidad de cuotas, si eliges la modalidad Tradicional, podrás devolver el préstamo en hasta 72 cuotas fijas y mensuales. Si optas por la modalidad UVA, podrás hacerlo en hasta 60 cuotas mensuales.
<br><br>
          
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
         
Forma de pago de las cuotas: Fácil y segura
<br><br>
El pago de las cuotas del Súper Préstamo Personal se realiza de forma cómoda y segura. Las cuotas serán debitadas automáticamente de tu SuperCuenta en la fecha de vencimiento establecida. En caso de que la cuota venza en un sábado, domingo o feriado, tendrás la opción de realizar el pago por Online Banking o la App Santander el mismo día, evitando recargos por atraso.
<br><br>
Fecha de vencimiento de las cuotas: Tu elección
<br><br>
Al simular tu préstamo, podrás seleccionar la fecha de vencimiento de tu primera cuota. Esta fecha se mantendrá para los meses siguientes, facilitando la planificación de tus pagos y permitiéndote tener un mayor control sobre tus finanzas.
<br><br>
Préstamo para no clientes: Opción disponible
<br><br>
Si aún no eres cliente del Santander , también tienes la posibilidad de acceder a un préstamo personal. Con una tasa del 79,5%, podrás solicitarlo en hasta 36 cuotas, cumpliendo requisitos específicos como ingresos mensuales declarados de al menos $60.000 y no estar informado en la central de deudores (CenDeu) a diciembre de 2020. Para conocer más detalles sobre esta modalidad, te recomendamos contactar al Santander  o visitar su sitio web oficial.


          
          
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PagePrestamosPersonalesSantander
